import React, { useContext } from "react";
import { Loplop } from "../context/Loplop";
import Gallery from "./Gallery";
import Loader from "./Loader";
const Container = ({ foto }) => {
  // console.log(foto);
  const { images } = useContext(Loplop);
  // useEffect(() => {
  //   runSearch(foto);
  // }, [foto]);

  return (
    <Gallery data={images} />
  );
};

export default Container;
