import React from "react";
// import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import NoImages from "./NoImages";
import Image from "./Image";
// import 'pure-react-carousel/dist/react-carousel.es.css';
const Gallery = props => {
  // console.log(props);
  const results = props.data;
  let images;
  let noImages;
  // map variables to each item in fetched image array and return image component
  if (results.length > 0) {
    images = results.map((image,id) => {
      let link='photo:' + id;
      // let farm = image.farm;
      // let server = image.server;
      // let secret = image.secret;
      // let title = image.title;
      // let url = `https://farm${farm}.staticflickr.com/${server}/${id}_${secret}_m.jpg`;
      // return <Image url={url} key={id} alt={title} />;
      return <Image url={image} key={id} link={link} />;
    });
  } else {
    noImages = <NoImages />; // return 'not found' component if no images fetched
  }
  return (
    <ul id="loplop" className="loplop">{images}</ul>
  );
};

export default Gallery;
