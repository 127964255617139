import React, { createContext } from "react";
export const Loplop = createContext();
const LoplopProvider = props => {
  const images = importAll(require.context('../photos/', true, /\.(png|jpe?g|svg)$/));
  // console.log(path);
  return (
    <Loplop.Provider value={{ images }}>
        {props.children}
    </Loplop.Provider>
  );
};

export default LoplopProvider;
function importAll(r) {
    return r.keys().map(r);
}