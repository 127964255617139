import React, { Component } from "react";
import LoplopProvider from "./context/Loplop";
import About from "./components/About";
import Animation from "./components/Animation";
import Item from "./components/Item";
import { HashRouter, Route, Switch } from "react-router-dom";
import './App.css';

class App extends Component {
  // Prevent page reload, clear input, set URL and push history on submit
  handleSubmit = (e, history, searchInput) => {
    e.preventDefault();
    e.currentTarget.reset();
    let url = `/search/${searchInput}`;
    history.push(url);
  };
  render() {
    return (
      <LoplopProvider>
        <HashRouter basename="/">
          <Switch>
            <Route
              exact
              path="/"
              render={() => <Route component={Animation} />}
            />
            <Route
              exact
              path="/photo:*"
              render={() => <Route component={Item} />}
            />
          </Switch>
          <Route component={About} />
        </HashRouter>
      </LoplopProvider>
    );
  }
}

function moveFirstChild(){
  let list = document.getElementById('loplop');
  let child = list.firstElementChild;
  let time;
  window.onload = resetTimer;
  document.onmousemove = resetTimer;
  document.onkeypress = resetTimer;
  function moveNode(){
    list.appendChild(child)
  }
  function resetTimer() {
      clearTimeout(time);
      time = setTimeout(moveNode, 10);
  }
}

document.addEventListener ('DOMContentLoaded',function () {
  const loplop = document.getElementById('loplop');
  loplop.addEventListener('click', function(event) {
    var closest = event.target.closest('.item');
    if (closest && loplop.contains(closest)) {
      loplop.firstElementChild.before(closest);
    }
  });
  setInterval(moveFirstChild, 4200);
});
export default App;
