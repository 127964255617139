import React, { useContext } from "react";
import { Loplop } from "../context/Loplop";
import Gallery from "./Gallery";

const Container = ({ foto }) => {
  const { images } = useContext(Loplop);
  return (
    <Gallery data={images} />
  );
};

export default Container;