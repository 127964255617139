import React from "react";
import Container from "./Container";

const Item = ({ foto }) => {
  return (
      <Container foto={foto} />
  );
};

export default Item;
