import React from "react";
import { Picture } from 'react-responsive-picture';
const About = ({  }) => {
  return (
    <article className="about">
      <Picture src="frank.jpg" className="frank" alt="Portrait of Frank Kilian, © by joki.de 2019" />
      <div className="text">
        <h1 className="headline">Frank Kilian</h1>
        <p className="paragraph">As a great admirer of Max Ernst my choice fell in 1998 on the domain name loplop.de - which is his <a className="external" href="https://www.ecosia.org/images?q=loplop">alter ego</a>.</p>
        <p className="paragraph">Today I use the website for purely private purposes and usually do not find enough time to do all the things I would like to try. ¯\_(ツ)_/¯</p>
        <p className="paragraph">As a hobby photographer I just show here a few pictures that for different reasons mean something to me or that I just like.</p>
        <p className="paragraph">Professionally, I enjoy designing and creating user interfaces and digital products.</p>
        <p className="paragraph">© of my portrait: <a className="external" href="joki.de">joki.de</a> 2019</p>
        <ul className="profiles">
          <li className="profile">
            <a href="https://twitter.com/fhkilian" target="_blank" rel="noopener noreferrer" className="external" title="Twitter: Frank Kilian">
              <Picture src="Twitter.svg" className="logo" alt="Frank Kilian on Twitter" />
            </a>
          </li>
          <li className="profile">
            <a href="https://www.linkedin.com/in/frank-kilian-0170123b/" target="_blank" rel="noopener noreferrer" className="external" title="LinkedIn: Frank Kilian">
              <Picture src="LinkedIn.svg" className="logo" alt="Frank Kilian on LinkedIn" />
            </a>
          </li>
          <li className="profile">
            <a href="https://www.xing.com/profile/Frank_Kilian" target="_blank" rel="noopener noreferrer" className="external" title="Xing: Frank Kilian">
              <Picture src="XING.svg" className="logo" alt="Frank Kilian on Xing" />
            </a>
          </li>
        </ul>
        <p className="profile">
          <a href="https://www.lima-city.de/?cref=400218" target="_blank" rel="noopener noreferrer" className="external" title="Webhosting, Domains &amp; Cloud von lima-city">
            Hosted by lima-city
          </a>
        </p>
      </div>
    </article>
  );
};
export default About;